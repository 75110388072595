:root {
  --white: #fff;
  --blue: #0ff;
  --pink: #f0f;
  --border: 1px solid var(--white);
}

/* ========================================== LAYOUT STYLES ========================================== */

body {
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #000;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  color: var(--white);
}

#wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header,
footer { background: rgba(0, 0, 0, .7); }

h2 { 
  font-size: 7vmin;
  font-family: 'Permanent Marker', cursive;
  margin: 16px 0;
}

ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

li { list-style-type: none; }

hr {
  height: 1px;
  border: 0;
  margin: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 95),
    rgba(255, 255, 255, 95),
    rgba(255, 255, 255, 0)
  );
}

#wrapper > hr {
  width: 10%;
  margin: 0 auto;
}

a { 
  color: var(--white);
  margin: 0 .2em;
  text-decoration: none;
}

a:visited { color: var(--white); }

a,
button { transition: 150ms; }

footer a { margin: 0 .5em; }

footer a:hover {
  color: var(--white);
  text-decoration: underline;
}

small {
  margin: .5em 0;
  display: inline-block;
}

/* ========================================== INTRO BLURB STYLES ========================================== */

.intro-blurb {
  max-width: 85vmin;
  margin: auto;
}

.intro-blurb li { margin-bottom: 1em; }

.intro-blurb li:first-of-type { margin-top: 1em; }

.pattern-sound { color: var(--pink); }

.pattern-sound { margin-bottom: 0; }

.italics { font-style: italic; }

.thinking-emoji {
  font-size: 2em;
  margin: 1rem 0 0;
}

/* ========================================== CONTROLS STYLES ========================================== */

.controls-wrapper { margin: 0 auto; }

form { 
  display: flex;
  margin: 1em 1em 2em;
  border: 1px solid var(--white);
  border-radius: 4px;
}

form > * { width: 33.3%; }

label,
button {
  text-transform: uppercase;
  letter-spacing: .4px;
  font-size: .7em;
}

label div,
input {
  font-family: system-ui;
  font-weight: 400;
}

label {
  padding: 3px 0;
  border-right: 1px solid var(--white);
  display: flex;
  flex-direction: column;
  align-items: center; 
}

label:last-of-type { position: relative; }

label div {
  position: absolute;
  bottom: 3px;
  text-transform: lowercase;
  font-size: 1rem;
  color: var(--pink);
  pointer-events: none 
}

input,
select { 
  background: none;
  outline: none;
  font-size: 1rem;
  border: none;
  width: 100%;
  cursor: pointer;
}

input {
  -webkit-appearance: none;
  color: var(--pink);
  text-align: center;
  padding: 0;
  caret-color: var(--white);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

select { 
  color: black;
  border-radius: 0;
  max-height: 18px;
}

.button-wrapper { padding: 3px 0; }

button {
  background-color: var(--white);
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  font-family: Oswald;
  height: 100%;
  min-width: 52px;
  width: calc(100% - 6px);
  color: #000;
}

/* ========================================== CHART STYLES ========================================== */

.chart { margin: 1em 0; }

.chart div {
  margin: auto;
  max-width: 650px;
}

@media all and (min-width: 500px) {
  .chart { 
    margin: 1.5em 2em;
  }
}

/* ========================================== SEQUENCE LIST STYLES ========================================== */

.sequence-list li { 
  transition: transform .3s;
  margin: 0 0 1em;
}

footer a { font-weight: 400; }

.even,
.odd { margin: 0 .25rem; }

.even { color: var(--blue); }
.odd { color: var(--pink); }

/* ========================================== ERROR BOUNDARY STYLES ========================================== */

.error-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--pink);
  color: black;
  margin: 0;
}
